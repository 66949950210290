.projectBody {
    width: 40%;
    margin: auto;
    justify-content: center;
    text-align: center;
    color: white;
}

.projectBody button {
    background-color: #9993B2;
    border: none;
    color: black;
    padding: 12px 16px;
    text-align: center;
    display: inline-block;
    margin-top: 20px;
    cursor: pointer;
    font-size: 14px;
    transition-duration: 0.4s;
}

.projectBody button:hover {
    background-color: #A7ABDD;
}

.projectBody img {
    width: 90%
}

@media only screen and (min-width: 481px) {

}

@media only screen and (max-width: 480px) {
    .projectBody {
        width: 80%;
        margin: auto;
        justify-content: center;
        text-align: center;
        color: white;
    }
}