.aboutBody {
  width: 40%;
  margin: auto;
  justify-content: center;
  text-align: center;
  color: white;
}

#resumedownload {
  background-color: #9993b2;
  border: none;
  color: black;
  padding: 16px 32px;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
  transition-duration: 0.4s;
}

#resumedownload:hover {
  background-color: #a7abdd;
}

h2 {
  padding-bottom: 5px;
  border-bottom: 1px solid white;
}

.list {
  text-align: center;
  overflow: hidden;
  list-style: none;
  padding: 0;
}

.listitem {
  line-height: 1.5rem;
  float: left;
  display: inline;
}

.listskills {
  text-align: left;
}

.listitemskills {
  display: inline-flex;
  justify-content: space-between;
}

.starcontainer {
  width: 55px;
  padding-right: 50px;
}

.star {
  background: gold;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  display: inline-block;
  height: 10px;
  width: 10px;
}

.staropen {
  background: white;
  border: 1px solid black;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  display: inline-block;
  height: 9px;
  width: 9px;
}

#experience div {
  text-align: left;
}

#experience h4 {
  margin: 0;
}

#experience p {
  margin: 0;
}

#experience ul {
  margin: 0;
  padding-top: 10px;
}

#experiencetitle {
  padding-top: 10px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

#experiencename {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

#education div {
  text-align: left;
}

#educationtitle {
  padding-top: 10px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

#education h4 {
  margin: 0;
}

#education p {
  margin: 0;
}

#education ul {
  margin: 0;
  padding-top: 10px;
}

@media only screen and (min-width: 481px) {
  #skillsmobile {
    display: none;
  }
  .listitem {
    width: 25%;
  }
  .listitemskills {
    width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .aboutBody {
    width: 80%;
    margin: auto;
    justify-content: center;
    text-align: center;
    color: white;
  }
  .starcontainer {
    width: 55px;
    padding-right: 10px;
  }
  #skills {
    display: none;
  }
  .listitem {
    width: 50%;
  }
}
