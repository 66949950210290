.navbar ul {
    background-color: black;
    display: flex;
    margin: auto;
    padding: 0;
    justify-content: center;
  
    letter-spacing: -0.075em;
    color: #FFFFFF;
    list-style-type: none;
}

.navbar ul li {
    float: left;
    padding: 1.5rem;
    margin: .2rem;
    font-size: 1.5rem;
}

.navbar ul li:hover {
    background-color: #819595;
}

#git {
    width: 3rem;
    height: 3rem;
    margin-top: 1.2rem;
}

#linkedIn {
    margin-left: .5rem;
    width: 2rem;
    height: 2rem;
    margin-top: 1.7rem;
}

@media only screen and (max-width: 480px) {
    .navbar ul li {
        font-size: 1rem;
        padding: 1.2rem
    }

    #git {
        width: 3rem;
        height: 3rem;
        margin-top: .6rem;
    }
    
    #linkedIn {
        width: 2rem;
        height: 2rem;
        margin-top: 1.2rem;
    }
}